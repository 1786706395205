import {AxiosResponse} from "axios";
import {UseFormReset, FieldValues} from "react-hook-form";
import moment from "moment";
import {GridColDef} from "@mui/x-data-grid";
import * as yup from "yup";
import _ from "lodash";
import {EQUIPMENT_TYPE, EQUIPMENT_CATEGORY_MAP, FUEL_TYPE, PIPING_CONFIG, SCHEDULE_TIME_FORMAT, VFD_LABEL_MAP} from "../config";
import {Field} from "../components/generics/inputs";
import {TransformData, FilterChipType, SearchType, ViewMode, OptionType, TransformConfig} from "../types";
import {SCHEDULE_SWITCH_KEYS, OVERRIDE_KEYS, SCHEDULE_KEYS, EQUIPMENTS} from "../components/forms";
import {PropertyFields} from "../views/Property";

const CELL_EMPTY_PLACEHOLDER="-";
const SCHEDULE_KEY_START_END:any=["start", "end"];

/**
 * resolveLabels
 * @param {any} labels
 * @param {any} payload
 * @param {any} labelsMap
 * @return {void}
 */
const resolveLabels=(labels:any, payload:any, labelsMap:any):void => {
    labelsMap.forEach((i:any) => {
        if (labels.find((v:string) => i.label===v)) payload[i.key]=true; // eslint-disable-line no-param-reassign
        else payload[i.key]=false; // eslint-disable-line no-param-reassign
    });
};

/**
 * resolveLabel
 * @param {any} labels
 * @param {any} value
 * @return {string|null}
 */
const resolveLabel=(labels:any, value:any):string|null => labels.find((m:any) => m.label===value)?.key || null;

/**
 * asyncForEach
 * @param {any[]} array
 * @param {any} callback
 * @return {Promise<void>}
 */
const asyncForEach= async (array:any[], callback:any):Promise<void> => {
    for (let index = 0; index < array.length; index++) { // eslint-disable-line no-plusplus
        await callback(array[index], index, array); // eslint-disable-line no-await-in-loop
    }
};

/**
 * cleanValue
 * @param {any} v
 * @return {string|undefined}
 */
const cleanValue=(v:any):string|undefined => {
    if (v===null || v===undefined) return undefined;
    return v;
};

/**
 * mutateProp
 * @param {any} opts
 * @param {ViewMode} m
 * @param {"readOnly"|"disabled"} k
 * @return {void}
 */
const mutateProp=(opts:any, m:ViewMode, k:"readOnly"|"disabled"="readOnly"):void => {
    if (opts && (k in opts)) { opts[k]=(!(["EDIT_MODE", "POST_MODE"].includes(m))); } // eslint-disable-line no-param-reassign
};

/**
 * mutateSwitchLogic
 * @param {boolean} value
 * @param {string} key
 * @param {PropertyFields} fields
 * @return {PropertyFields}
 */
const mutateSwitchLogic=(value:boolean, key:string, fields:PropertyFields):PropertyFields => {
    const targetKey=SCHEDULE_SWITCH_KEYS.find((k:string) => k!==key) as string;
    /**
     * setSchedule
     * @param {PropertyFields} _fields
     * @param {boolean} _value
     * @return {PropertyFields}
     */
    const setSchedule=(_fields:PropertyFields, _value:boolean):PropertyFields => ({
        ..._fields,
        propertyDetails: _fields.propertyDetails.map((field:Field) => {
            const newField=_.cloneDeep(field);
            if (newField.key===targetKey && newField.switchOptions) newField.switchOptions.disabled=_value;
            else if (SCHEDULE_KEYS.includes(newField.key) && newField.timepickerOptions) newField.timepickerOptions.disabled=_value;
            return newField;
        }),
    });

    fields=setSchedule(fields, value); // eslint-disable-line no-param-reassign
    return fields;
};

/**
 * mutateSwitchDependency
 * @param {any} vals
 * @param {string} key
 * @param {PropertyFields} fields
 * @param {UseFormReset<FieldValues>} reset
 * @return {PropertyFields}
 */
const mutateSwitchDependency=(vals:any, key:string, fields:PropertyFields, reset:UseFormReset<FieldValues>):PropertyFields => {
    // block treats switch and schedule logic dependencies
    if (SCHEDULE_SWITCH_KEYS.includes(key)) fields=mutateSwitchLogic(vals[key], key, fields); // eslint-disable-line no-param-reassign

    const schedule:Field[]=fields.propertyDetails.filter((field:Field) => SCHEDULE_KEYS.includes(field.key));
    // 24 Hour Facility schedule reselution
    if (key==="is24HourFacility" && vals[key]===true) {
        const newSchedule=schedule.reduce((a:any, v:Field) => ({...a, [v.key]: v.key.indexOf("_start")!==-1?moment().startOf("day").toDate():moment().endOf("day").toDate()}), {});
        reset({...vals, ...newSchedule});
    } else if (key==="is24HourFacility" && vals[key]===false) {
        const newSchedule=schedule.reduce((a:any, v:Field) => ({...a, [v.key]: null}), {});
        reset({...vals, ...newSchedule});
    }

    // same daily schedule reselution
    if (vals.isSameDailySchedule===true && OVERRIDE_KEYS.includes(key)) {
        const newSchedule=schedule.reduce((a:any, v:Field) => ({...a, [v.key]: v.key.indexOf("_start")!==-1?vals.override_start:vals.override_end}), {});
        reset({...vals, ...newSchedule});
    } else if (vals.isSameDailySchedule===false && vals.is24HourFacility!==true) {
        const newSchedule=schedule.reduce((a:any, v:Field) => ({...a, [v.key]: null}), {});
        reset({...vals, ...newSchedule, ...{override_start: null, override_end: null}});
    }
    return fields;
};

/**
 * alterEditableFieldsViewMode
 * @param {any} fields
 * @param {ViewMode} viewMode
 */
const alterEditableFieldsViewMode=(fields:any, viewMode:ViewMode):any => {
    // deeply clone form fields
    const clone:any=_.cloneDeep(fields);
    // iterate over and update readOnly fields
    Object.keys(clone).forEach((key:string) => {
        clone[key].forEach((field:Field) => {
            mutateProp(field.textfieldOptions, viewMode);
            mutateProp(field.timepickerOptions, viewMode);
            mutateProp(field.switchOptions, viewMode, "disabled");
            mutateProp(field.autocompleteOptions, viewMode);
            mutateProp(field.labelselectorOptions, viewMode, "disabled");
            mutateProp(field.pointsOptions, viewMode, "disabled");
        });
    });
    return clone;
};

/**
 * resolveFormKey
 * @param {string} key
 * @return {any}
 */
const resolveFormKey=(key:string):any => {
    const isNested=key?.match(/.\d+./)!==null;
    if (isNested) {
        const [root, index, sub]=key.split(".");
        return ({isNested, key: {root, index, sub}});
    }
    return ({isNested, key});
};

/**
 * populateValues
 * @param {string[]|OptionType[]} options
 * @param {Field[]} fields
 * @param {string} key
 * @return {void}
 */
const populateValues=(options:string[]|OptionType[], fields:Field[], key:string):void => {
    if (Array.isArray(options)) {
        const fieldProps=fields.find((field:Field) => {
            const fieldKeyType=resolveFormKey(field.key);
            if (fieldKeyType.isNested) return fieldKeyType.key.sub===key;
            return fieldKeyType.key===key;
        });
        if (fieldProps?.autocompleteOptions) fieldProps.autocompleteOptions.options=options;
        else if (fieldProps?.labelselectorOptions) fieldProps.labelselectorOptions.options=options as string[];
    }
};

/**
 * resolveSliderChipValue
 * template "<LABEL> <VALUE> to <VALUE>"
 * @param {formValue} formValue
 * @param {Field} field
 * @return {string|undefined}
 */
const resolveSliderChipValue=(formValue:any, field:Field):string|undefined => {
    // form value is js slider object -- Slider Component specific
    if (typeof formValue!=="object" || formValue===null) return undefined;
    // set label
    let valueTemplate=`${field.label} `;
    // get slider sub keys
    const subKeys=Object.keys((formValue));
    // conuter
    let c=0;
    // iterate over slider keys
    subKeys.forEach((subKey:string, index:number) => {
        // get form Field default values
        const defaults:any=field.sliderOptions?.default;
        // increament when slider value is equal to form defualt
        if (defaults[subKey]===formValue[subKey]) c+=1;
        // append value
        valueTemplate+=field.sliderOptions?.formatValue?(formValue[subKey]?.toLocaleString()||""):formValue[subKey];
        // append keyword "to"
        if (index+1<subKeys.length) valueTemplate+=" to ";
    });
    // set value if values are not defaults
    if (c!==2) return valueTemplate;
    return undefined;
};

/**
 * resolveFilterChipValues
 * @param {any} formValues
 * @param {Field[]} formFields
 * @param {string} chipKey
 * @param {SearchType|null} search
 * @param {boolean} isArchived
 * @return {FilterChipType}
 */
const resolveFilterChipValues=(formValues:any, formFields:Field[]|null, chipKey:string|null, search:SearchType|null, isArchived:boolean|null):FilterChipType => {
    // result object
    const result:FilterChipType={query: new URLSearchParams(), filterFields: {}, isQuery: undefined};

    /**
     * resolveIsQuery
     */
    const resolveIsQuery=():void => {
        // if (isArchived===false) result.isQuery=result.query.size!==1; // NOTE: used in event `archived` is always parsed
        result.isQuery=result.query.size!==0;
    };

    // resolve search
    if (search) result.query.set(search.key, search.value);
    // resolve isArchived
    if (isArchived===true) result.query.set("archived", isArchived.toString());
    // escape if no form or no fields
    if (!formValues || !formFields) {
        resolveIsQuery();
        return result;
    }
    // iterate over form values
    Object.keys(formValues).forEach((key:string) => {
        // escape chipKey if exist
        if (key===chipKey) return;
        // get form Field
        const field:Field=formFields.find((f:Field) => f.key===key) as Field;
        // resolve Slider Chip
        const sliderChip=resolveSliderChipValue(formValues[key], field);
        if (sliderChip) {
            result.filterFields[key]=sliderChip;
            // Slider key -> value; reversing values for api call
            // Example year_built__le=MAX&year_built__ge=MIN
            const subValues:any=Object.values(formValues[key]).reverse() as number[];
            const subKeys:string[]=Object.keys(formValues[key]);
            for (let idx=0; idx<subKeys.length; idx+=1) {
                result.query.set(subKeys[idx], subValues[idx]);
            }
        } else if (typeof formValues[key]==="boolean" && formValues[key]===true) {
            result.filterFields[key]=key;
            result.query.set(key, `${formValues[key]}`);
        } else if (typeof formValues[key]!=="object" && typeof formValues[key]!=="boolean" && formValues[key]!==null) {
            // NOTE form Field type defail can be object | boolean | null -- for now!
            result.filterFields[key]=formValues[key];
            result.query.set(key, formValues[key]);
        }
    });

    resolveIsQuery();

    return result;
};

/**
 * push
 * @param {any} v
 * @param {string[]} arr
 */
const push=(v:any, arr:string[]):void => { if (!arr.includes(v) && v!==undefined) arr.push(v); };

/**
 * shouldPush
 * Form Filter keys MUST be same as row keys
 * Filter using && operator
 * @param {any} _row
 * @param {any} _filter
 * @param {boolean|null} _isArchived
 * @param {SearchType|null} _search
 */
const shouldPush=(_row:any, _filter?:any, _isArchived?:boolean|null, _search?:SearchType|null):boolean => {
    const isDefined=(p:any):boolean => p!==undefined && p!==null; // eslint-disable-line require-jsdoc

    let result=true;
    if (isDefined(_filter)) {
        // clean filter values -- value is NOT (null, true, CELL_EMPTY_PLACEHOLDER);
        const cleanedFilter=Object.keys(_filter).reduce((obj:any, key:any) => {
            if (!(_filter[key]===null || _filter[key]===CELL_EMPTY_PLACEHOLDER || (typeof _filter[key]==="boolean" && _filter[key]===false))) obj[key] = _filter[key]; // eslint-disable-line no-param-reassign
            return obj;
        }, {});
        // Then test against current row value using same key
        for (const [key, value] of Object.entries(cleanedFilter)) { // eslint-disable-line no-restricted-syntax
            if (key==="vfd") {
                // resolve yes/no row and true/false filter
                const label=VFD_LABEL_MAP.find((el:any) => value===el.key)?.label;
                if (label!==_row[key]) return false;
            } else if (value!==_row[key]) return false;
        }
    }
    // archived chip boolean test
    if (isDefined(_isArchived)) result=result && _isArchived===_row.archived;
    // search test
    // TODO: Convert the search value and the row name into an array of strings, and check those for a match,
    // instead of a direct comparison. This will allow for partial matches.
    if (isDefined(_search)) result=result && _search?.value.toLowerCase()===_row.name.toLowerCase();
    return result;
};

/**
 * concatenateEquipmentResponse
 * @param {AxiosResponse} response
 */
const concatenateEquipmentResponse=(response:AxiosResponse):AxiosResponse => {
    // escape when status is NOT 200 OR results is not array
    if (response.status!==200 || !Array.isArray(response?.data?.results)) return response;
    // re-create response
    const payload:AxiosResponse={...response, data: {...response.data, results: []}};
    // iterate over batch
    response.data.results.forEach((batch:any) => {
        // resolve none equipment keys
        const lostKeys=Object.keys(batch).filter((k:string) => EQUIPMENTS.find((i:any) => i.key===k)===undefined);
        // iterate over each equipment in batch
        Object.keys(batch).forEach((key:string) => {
            // test if value is array
            if (Array.isArray(batch[key])) {
                batch[key].forEach((item:any) => {
                    // pass item AND lost keys under _
                    const row={...item, key, _: lostKeys.reduce((a:any, k:any) => ({...a, [k]: batch[k]}), {})};
                    payload.data.results.push(row);
                });
            }
        });
    });
    return payload;
};

/**
 * transformEquipmentData
 * DataGrid specific
 * @param {AxiosResponse|null} response
 * @param {GridColDef} colDef
 * @param {TransformConfig} config
 * @return {TransformData}
 */
const transformEquipmentData=(response:AxiosResponse|null, colDef:GridColDef[], config:TransformConfig):TransformData => {
    // result
    const content:TransformData={
        response: {...response, data: {results: []}} as AxiosResponse,
        categories: [],
        equipments: [],
        types: [],
    };
    // escape if no response
    if (!response) {
        content.response=null;
        return content;
    }
    // escape when response exists however NOT 200 status_code
    if (response?.status!==200 && content.response) {
        content.response=response;
        return content;
    }

    let transformedResponse={...response};
    const isDetail=!("results" in response.data);

    // re-shape payload schema
    if (isDetail) transformedResponse={...response, data: {results: [response.data]}};
    // should concat transformed Response
    let mergedResponse=transformedResponse;
    if (config.concat) mergedResponse=concatenateEquipmentResponse(transformedResponse);

    let gridId=0;
    mergedResponse?.data?.results?.forEach((record:any) => {
        // init empty row
        const row:any={...record, gridId};
        gridId+=1;
        // iterate over grid columns
        colDef.forEach((col:GridColDef) => {
            let fieldValue=record[col.field];
            // format flag controls modifying key/value resolution
            if (config.format) {
                if (col.field==="plant") fieldValue=EQUIPMENT_CATEGORY_MAP.find((i:any) => (i.key===fieldValue))?.label;
                else if (col.field==="equipment") fieldValue=EQUIPMENTS.find((i:any) => (record.variant?(i.key===record.key && i.variant===record.variant):(i.key===record.key)))?.label;
                else if (col.field==="type") fieldValue=EQUIPMENT_TYPE.find((i:any) => i.key===(record.chiller_type||fieldValue))?.label;
                else if (col.field==="piping_config") fieldValue=PIPING_CONFIG.find((i:any) => i.key===fieldValue)?.label;
                else if (col.field==="fuel_type") fieldValue=FUEL_TYPE.find((i:any) => i.key===fieldValue)?.label;
                else if (col.field==="vfd") fieldValue=VFD_LABEL_MAP.find((i:any) => i.key===fieldValue)?.label;
            }
            // setting value placeholder
            row[col.field]=fieldValue===undefined||fieldValue===null||fieldValue===""?CELL_EMPTY_PLACEHOLDER:fieldValue;
            // filter data population specific
            if (col.field==="plant" && row[col.field]!==CELL_EMPTY_PLACEHOLDER) push(row[col.field], content.categories);
            else if (col.field==="equipment" && row[col.field]!==CELL_EMPTY_PLACEHOLDER) push(row[col.field], content.equipments);
            else if (col.field==="type" && row[col.field]!==CELL_EMPTY_PLACEHOLDER) push(row[col.field], content.types);
        });
        // filter test and push row to results
        if (shouldPush(row, config.filter, config.isArchived, config.search)) content.response!.data.results.push(row);
    });
    // set total count
    content.response!.data.total_count=gridId;

    return content;
};

/**
 * validateId
 * "id" traits [positive, integer]
 * @param {any} id
 * @return {Promise<boolean>}
 */
const validateId=async (id:any):Promise<boolean> => (
    yup
        .number()
        .integer()
        .moreThan(-1)
        .validate(id)
        .then((value:any) => true)
        .catch((err:any) => false));

/**
 * selectDateRange
 * @param {any[]} values
 * @param {string} k
 * @return {any[]}
 */
const selectDateRange=(values:any, k:"start"|"end"):any[] => Object.values(_.pickBy(values, (value, key):boolean => key.endsWith(k) && SCHEDULE_KEYS.includes(key)));

/**
 * resolveSameDailySchedule
 * @param {any} values
 * @returns {boolean}
 */
const resolveSameDailySchedule=(values:any): boolean => {
    for (let c=0; c<SCHEDULE_KEY_START_END.length; c+=1) {
        const range=selectDateRange(values, SCHEDULE_KEY_START_END[c]);
        if (!range.every((d:string) => moment(d, SCHEDULE_TIME_FORMAT).isSame(moment(range[0], SCHEDULE_TIME_FORMAT)))) return false;
    }
    return true;
};

/**
 * resolve24HourFacility
 * @param {any} values
 * @returns {boolean}
 */
const resolve24HourFacility=(values: any):boolean => {
    for (let c=0; c<SCHEDULE_KEY_START_END.length; c+=1) {
        const range=selectDateRange(values, SCHEDULE_KEY_START_END[c]);
        if (!range.every((d:string) => moment(d, SCHEDULE_TIME_FORMAT).isSame(moment(c===0?"00:00:00":"23:59:59", SCHEDULE_TIME_FORMAT)))) return false;
    }
    return true;
};

export {
    asyncForEach,
    populateValues,
    resolveFilterChipValues,
    transformEquipmentData,
    validateId,
    cleanValue,
    alterEditableFieldsViewMode,
    mutateSwitchDependency,
    resolveFormKey,
    resolveSameDailySchedule,
    resolve24HourFacility,
    mutateSwitchLogic,
    CELL_EMPTY_PLACEHOLDER,
    resolveLabel,
    resolveLabels,
};
export * from "./property";
export * from "./bas";
export * from "./equipment";
export * from "./changelog";

import React from "react";
import {Divider, Grid} from "@mui/material";
import * as yup from "yup";
import {useFormContext} from "react-hook-form";
import {Field} from "../../generics/inputs";
import {constructLayout} from "./aux";

interface TrendingProps{
    fields:Field[]
    controllingFields:any
}

// NOTE: fields "ORDER" matters; see controllingFields under BasDetails
export const Fields:Field[] = [
    // ------ section 1
    {
        key: "trending_view_charts_as_graph",
        label: "Can you generally view trends charts as a graph?",
        yup: yup.mixed().nullable(),
        type: "switch",
        switchOptions: {
            disabled: true,
        },
        exportOptions: {
            label: "BAS_Can you generally view trends charts as a graph?",
            order: 18,
        },
    },
    {
        key: "trending_lines_proper_axis_scaling",
        label: "Proper axis scaling?",
        yup: yup.mixed().nullable(),
        type: "switch",
        switchOptions: {
            disabled: true,
        },
        exportOptions: {
            label: "BAS_Proper axis scaling",
            order: 19,
        },
    },
    {
        key: "trending_lines_clear",
        label: "Clear lines / colors?",
        yup: yup.mixed().nullable(),
        type: "switch",
        switchOptions: {
            disabled: true,
        },
        exportOptions: {
            label: "BAS_Clear lines/ colors",
            order: 20,
        },
    },
    {
        key: "trending_lines_labeled_axis_legend",
        label: "Labeled axis and legend?",
        yup: yup.mixed().nullable(),
        type: "switch",
        switchOptions: {
            disabled: true,
        },
        exportOptions: {
            label: "BAS_Labeled axis and legend",
            order: 21,
        },
    },
    {
        key: "trending_lines_notes",
        label: "Notes",
        yup: yup.string(),
        type: "textfield",
        textfieldOptions: {
            readOnly: true,
            maxLength: 1000,
            textArea: {
                multiline: true,
                rows: 10,
            },
        },
    },
    // ------ section 2
    {
        key: "trending_charts_create_or_modify",
        label: "Can you generally create new trend charts and/or modify existing trend charts?",
        yup: yup.mixed().nullable(),
        type: "switch",
        switchOptions: {
            disabled: true,
        },
        exportOptions: {
            label: "BAS_Can you generally create new trend charts and/or modify existing trend charts",
            order: 22,
        },
    },
    {
        key: "trending_charts_change_points",
        label: "Change what points are trended?",
        yup: yup.mixed().nullable(),
        type: "switch",
        switchOptions: {
            disabled: true,
        },
        exportOptions: {
            label: "BAS_Change what points are trended",
            order: 23,
        },
    },
    {
        key: "trending_charts_edit_axis_labels",
        label: "Edit axis labels?",
        yup: yup.mixed().nullable(),
        type: "switch",
        switchOptions: {
            disabled: true,
        },
        exportOptions: {
            label: "BAS_Edit the axis labels",
            order: 24,
        },
    },
    {
        key: "trending_charts_secondary_axis",
        label: "Add a secondary axis?",
        yup: yup.mixed().nullable(),
        type: "switch",
        switchOptions: {
            disabled: true,
        },
        exportOptions: {
            label: "BAS_Add a secondary axis",
            order: 25,
        },
    },
    {
        key: "trending_charts_notes",
        label: "Notes",
        yup: yup.string(),
        type: "textfield",
        textfieldOptions: {
            readOnly: true,
            maxLength: 1000,
            textArea: {
                multiline: true,
                rows: 10,
            },
        },
        exportOptions: {
            label: "BAS_Create Trend Charts_Notes",
            order: 26,
        },
    },
    // ------ section 3
    {
        key: "trending_points_can_enable_new",
        label: "Can you generally enable new trend points?",
        yup: yup.mixed().nullable(),
        type: "switch",
        switchOptions: {
            disabled: true,
        },
        exportOptions: {
            label: "BAS_Can you generally enable new trend points?",
            order: 27,
        },
    },
    {
        key: "trending_points_from_cov_to_time_interval",
        label: "Change from COV to time interval data?",
        yup: yup.mixed().nullable(),
        type: "switch",
        switchOptions: {
            disabled: true,
        },
        exportOptions: {
            label: "BAS_Change from COV to time interval data?",
            order: 28,
        },
    },
    {
        key: "trending_points_time_interval_to_fifteen_minutes",
        label: "Change the time interval to 15 min?",
        yup: yup.mixed().nullable(),
        type: "switch",
        switchOptions: {
            disabled: true,
        },
        exportOptions: {
            label: "BAS_Change the time interval to 15 min?",
            order: 29,
        },
    },
    {
        key: "trending_points_history",
        label: "Change the history to gather 2 weeks worth of data?",
        yup: yup.mixed().nullable(),
        type: "switch",
        switchOptions: {
            disabled: true,
        },
        exportOptions: {
            label: "BAS_Change the history to gather 2 weeks worth of data?",
            order: 30,
        },
    },
    {
        key: "can_download_trend_chart_csv",
        label: "Can you download trend chart data as a CSV file?",
        yup: yup.mixed().nullable(),
        type: "switch",
        switchOptions: {
            disabled: true,
        },
        exportOptions: {
            label: "BAS_Can you download trend chart data as a CSV file?",
            order: 31,
        },
    },
    {
        key: "trending_points_notes",
        label: "Notes",
        yup: yup.string(),
        type: "textfield",
        textfieldOptions: {
            readOnly: true,
            maxLength: 1000,
            textArea: {
                multiline: true,
                rows: 14,
            },
        },
        exportOptions: {
            label: "BAS_Download Trends_Notes",
            order: 32,
        },
    },
];

/**
 * Trending
 * @param {TrendingProps} props
 * @return {React.ReactElement}
 */
function Trending(props:TrendingProps):React.ReactElement {
    const methods=useFormContext();

    return (
        <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="flex-start">
            {/* section 1 */}
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                {constructLayout({
                    fields: props.fields,
                    methods,
                    controllingFields: props.controllingFields,
                    controllingKey: "trending_view_charts_as_graph",
                    textAreaRows: {min: 1, max: 10},
                    spacing: {min: 0, max: 3},
                })}
            </Grid>
            {/* divider */}
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}><Divider /></Grid>
            {/* section 2 */}
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                {constructLayout({
                    fields: props.fields,
                    methods,
                    controllingFields: props.controllingFields,
                    controllingKey: "trending_charts_create_or_modify",
                    textAreaRows: {min: 1, max: 10},
                    spacing: {min: 0, max: 3},
                })}
            </Grid>
            {/* divider */}
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}><Divider /></Grid>
            {/* section 3 */}
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                {constructLayout({
                    fields: props.fields,
                    methods,
                    controllingFields: props.controllingFields,
                    controllingKey: "trending_points_can_enable_new",
                    textAreaRows: {min: 3, max: 14},
                    spacing: {min: 0.5, max: 3},
                    collapsedSize: 140,
                })}
            </Grid>
        </Grid>
    );
}

export default Trending;

import React, {useState, ReactElement, SetStateAction, Dispatch, forwardRef} from "react";
import {GridToolbarContainer, GridColDef, GridPaginationModel} from "@mui/x-data-grid";
import {Theme, ThemeProvider, createTheme} from "@mui/material/styles";
import {ClickAwayListener} from "@mui/base";
import {AxiosResponse} from "axios";
import {
    Box,
    Popper,
    Fade,
    Paper,
    Button,
    Grid,
    Skeleton,
    Chip,
    TablePagination,
} from "@mui/material";
import _ from "lodash";
import {FilterList, Article} from "@mui/icons-material";

interface FilterContainerProps{
    filter:ReactElement
    state:any
    setState:Dispatch<SetStateAction<any>>
}

interface ActionProps{
    key:string
    label:string
    icon:React.ReactElement
    onClick: (row:any) => (args:React.MouseEvent) => void
    color?:"primary" | "secondary" | "error" | "inherit" | "success" | "info" | "warning",
    hide?: {key:string, value:string}
}

export interface DataGridProps{
    columns:GridColDef[]
    rows?:AxiosResponse|null
    url?:string
    getRowId?:any
    rowId?:string
    filter?:React.ReactElement
    search?:React.ReactElement
    primaryAction?:React.ReactElement
    filterFields?:any
    onFilterFieldsChange?:(key:string) => (args:React.MouseEvent) => void
    actions?:ActionProps[]
    state?:any
    setState?:Dispatch<SetStateAction<any>>
    transformData?:(response:AxiosResponse, colDef:GridColDef[]) => AxiosResponse
    isQuery?:boolean
    baseNoRowsOverlay?:React.ReactElement
    noRowsOverlay?:React.ReactElement
}

interface ActionContainerProps{
    anchor:HTMLElement|null
    setAnchor:Dispatch<SetStateAction<HTMLElement|null>>
    actions:ActionProps[]
    row:any
}

interface ToolbarProps extends Omit<DataGridProps, "columns"|"url"|"getRowId"|"actions"|"transformData">{
    paginationModel:GridPaginationModel
    onShowArchived:(args:React.MouseEvent) => void
    onPageChange:(args: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void
    onRowsPerPageChange:(args: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    count:number
    isLoading:boolean
}

const buttonTheme:Theme=createTheme({
    palette: {
        mode: "dark",
        primary: {
            main: "#333B39",
        },
        secondary: {
            main: "#EF6C00",
        },
    },
});

const paginationtheme:Theme=createTheme({
    palette: {
        mode: "dark",
        primary: {
            main: "#333B39",
        },
    },
});

const chipTheme:Theme=createTheme({
    palette: {
        mode: "dark",
        primary: {
            main: "#3C8131",
        },
    },
});

/**
 * Placeholder
 * @return {React.ReactElement}
 */
function Placeholder():React.ReactElement {
    return (
        <Grid container spacing={1} direction="row" justifyContent="flex-start" alignItems="flex-start">
            <Grid item xs={12} sx={{display: "flex"}}>
                <Skeleton variant="rounded" width={150} height={30} sx={{marginRight: "12px"}} />
                <Skeleton variant="rounded" width={150} height={30} />
                <Box sx={{flexGrow: 1}} />
                <Skeleton variant="rounded" width={350} height={30} />
            </Grid>
            <Grid item xs={12}><Skeleton variant="rounded" width="100%" height={300} /></Grid>
        </Grid>
    );
}

/**
 * FilterContainer
 * filter status boolean passed from root component. needed to control Popper triggering
 * @param {FilterContainerProps} props
 * @return {React.ReactElement}
 */
function FilterContainer(props:FilterContainerProps):React.ReactElement {
    const [anchor, setAnchor]:[HTMLElement|null, Dispatch<SetStateAction<HTMLElement|null>>]=useState<null | HTMLElement>(null);
    /**
     * onFilterClick
     * @param {React.MouseEvent} args
     */
    const onFilterClick=(args:React.MouseEvent):void => props.setState({..._.cloneDeep(props.state), filterStatus: !props.state.filterStatus});

    /**
     * onClickAway
     * @param {MouseEvent|TouchEvent} args
     */
    const onClickAway=(args:MouseEvent|TouchEvent):void => {
        if (props.state.filterStatus===true) props.setState({..._.cloneDeep(props.state), filterStatus: false});
    };

    return (
        <ClickAwayListener onClickAway={onClickAway}>
            <Box>
                <Button sx={{color: "#FFFFFF"}} onClick={onFilterClick} ref={(node:any) => setAnchor(node)}>
                    <Box sx={{marginTop: "4px"}}><FilterList /></Box>
                    Filters
                </Button>
                {props.state.filterStatus && <Popper open={Boolean(anchor)} modifiers={[{name: "flip", enabled: false}]} placement="bottom-start" anchorEl={anchor}>{props.filter}</Popper>}
            </Box>
        </ClickAwayListener>
    );
}

/**
 * ActionContainer
 * @param {ActionContainerProps} props
 * @return {React.ReactElement}
 */
const ActionContainer=forwardRef((props:ActionContainerProps, ref:any):React.ReactElement => (
    <Popper
        ref={ref}
        open={Boolean(props.anchor)}
        anchorEl={props.anchor}
        placement="right"
        transition
        modifiers={[
            {
                name: "flip",
                enabled: false,
                options: {
                    altBoundary: true,
                    rootBoundary: "document",
                    padding: 8,
                },
            },
            {
                name: "preventOverflow",
                enabled: true,
                options: {
                    altAxis: true,
                    altBoundary: true,
                    tether: true,
                    rootBoundary: "document",
                    padding: {right: 16},
                },
            },
        ]}
        onMouseLeave={() => props.setAnchor(null)}
    >
        {({TransitionProps}) => (
            <Fade {...TransitionProps} timeout={350}>
                <Paper elevation={0}>
                    {props.actions.map((action:ActionProps) => {
                        if (action.hide && props.row[action.hide.key]===action.hide.value) return null;
                        return (
                            <ThemeProvider theme={buttonTheme} key={action.key}>
                                <Button
                                    variant="text"
                                    sx={{backgroundColor: "#0000000a", borderRadius: "0px", textTransform: "capitalize"}}
                                    color={action.color||"primary"}
                                    onClick={action.onClick(props.row)}
                                >
                                    <Box sx={{marginRight: "6px", marginBottom: "-6px"}}>{action.icon}</Box>
                                    {action.label}
                                </Button>
                            </ThemeProvider>
                        );
                    })}
                </Paper>
            </Fade>
        )}
    </Popper>
));

/**
 * Toolbar
 * @param {ToolbarProps} props
 * @return {React.ReactElement}
 */
function Toolbar(props:ToolbarProps):React.ReactElement {
    return (
        <GridToolbarContainer sx={{backgroundColor: "#333B39", padding: "8px"}}>
            <Grid container columnSpacing={1} direction="row" justifyContent="flex-start" alignItems="center">
                {/* filter */}
                {props.filter && props?.state!==undefined && props.setState!==undefined && (
                    <Grid sx={{width: "12%"}} item><FilterContainer state={props?.state} setState={props.setState} filter={props.filter} /></Grid>
                )}
                {/* chips */}
                {props.filterFields && (
                    <Grid item sx={{"& .MuiGrid-item": {paddingLeft: "0 !important;"}, width: "50%"}}>
                        <Grid sx={{gap: "8px"}} container columnSpacing={1} direction="row" justifyContent="flex-start" alignItems="flex-start">
                            {Object.keys(props.filterFields).map((key:string) => (
                                <Grid sx={{flexWrap: "wrap", padding: "0"}} item key={key}>
                                    <ThemeProvider theme={chipTheme}>
                                        <Chip
                                            sx={{"& >.MuiGrid-item": {padding: "0"}}}
                                            color="primary"
                                            label={props.filterFields[key]}
                                            onDelete={props.onFilterFieldsChange && props.onFilterFieldsChange(key)}
                                        />
                                    </ThemeProvider>
                                </Grid>
                            ))}
                            {/* showArchived flag */}
                            {props.state && ("showArchived" in props.state) && (
                                <Grid item sx={{marginLeft: "8px"}}>
                                    <ThemeProvider theme={chipTheme}>
                                        <Chip
                                            color={props?.state?.showArchived===true?"primary":"default"}
                                            sx={{borderColor: "#FFFFFF"}}
                                            variant={props?.state?.showArchived===true?"filled":"outlined"}
                                            label="Show Archived"
                                            onClick={props.onShowArchived}
                                        />
                                    </ThemeProvider>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                )}
                {/* pagination */}
                <Grid item sx={{flexGrow: 1, width: "38%"}}>
                    <ThemeProvider theme={paginationtheme}>
                        <TablePagination
                            component="div"
                            count={props?.rows?.data && Array.isArray(props?.rows?.data?.results)?props.rows.data.results.length:props.count}
                            page={props.paginationModel.page}
                            onPageChange={props.onPageChange}
                            rowsPerPage={props.paginationModel.pageSize}
                            onRowsPerPageChange={props.onRowsPerPageChange}
                            rowsPerPageOptions={[25, 50, 100]}
                            disabled={props.isLoading}
                        />
                    </ThemeProvider>
                </Grid>
            </Grid>
        </GridToolbarContainer>
    );
}

/**
 * NoRowsOverlay
 * @return {React.ReactElement}
 */
function NoRowsOverlay():React.ReactElement {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
            }}
        >
            <Article sx={{fontSize: "60px"}} />
            No Rows
        </Box>
    );
}

/**
 * BaseSkeleton
 * @return {React.ReactElement}
 */
function BaseSkeleton():React.ReactElement {
    return (
        <Grid container spacing={2} direction="row" justifyContent="space-between" alignItems="center">
            <Grid item xs={10}><Skeleton variant="rounded" width={400} height={60} /></Grid>
            <Grid item xs={2}><Skeleton variant="rounded" width="100%" height={60} /></Grid>
            <Grid item xs={12}><Skeleton variant="rounded" width="100%" height={600} /></Grid>
        </Grid>
    );
}

export {
    ActionContainer,
    Placeholder,
    Toolbar,
    NoRowsOverlay,
    BaseSkeleton,
};

const EQUIPMENT_CATEGORY_MAP:any=[
    {key: "air", label: "Air Handling", category: "airhandling"},
    {key: "cooling", label: "Cooling", category: "cooling"},
    {key: "heating", label: "Heating", category: "heating"},
    {key: "terminal", label: "Terminal Units", category: "terminal"},
];

const FAMILIARITY_LABEL_MAP=[
    {key: "introductory", label: "Introductory"},
    {key: "general", label: "General"},
    {key: "advanced", label: "Advanced"},
];

const DOCUMENT_LABEL_MAP=[
    {key: "mechanical_plans_drawings", label: "Mechanical plans / drawings", type: "building"},
    {key: "sequences_of_operations", label: "Sequences of operations", type: "building"},
    {key: "audits_or_eer", label: "Energy audits or other energy efficiency reports (EER)", type: "building"},
    {key: "reports_commisioning", label: "Commissioning reports", type: "building"},
    {key: "mechanical_schedules", label: "Mechanical schedules", type: "building"},
    {key: "existing_bas_service_contracts", label: "Existing BAS service contracts", type: "building"},
    {key: "bas_as_builts", label: "BAS as-builts", type: "building"},
    {key: "bas_survey", label: "BAS Survey", type: "course"},
    {key: "tracking_sheet", label: "Tracking Sheet", type: "course"},
    {key: "session_materials", label: "Session Materials", type: "course"},
];

const DOCUMENT_TYPE_LABEL_MAP=[
    {key: "building", label: "Property Document"},
    {key: "course", label: "Course Document"},
];

const METERINFO_TYPE_LABEL_MAP=[
    {key: "electric", label: "Electric"},
    {key: "gas", label: "Gas"},
    {key: "steam", label: "Steam"},
];

const METERINFO_SOURCE_LABEL_MAP=[
    {key: "ConEd AMI", label: "ConEd AMI"},
    {key: "NuEnergen", label: "NuEnergen"},
];

const ACCESS_LEVEL_LABEL_MAP=[
    {key: "read_only", label: "Read Only"},
    {key: "read_write", label: "Read/Write"},
];

const COMMUNICATION_PROTOCOLS=[
    {key: "comm_protocol_is_bacnet", label: "BACnet"},
    {key: "comm_protocol_is_lontalk", label: "LonTalk"},
    {key: "comm_protocol_is_modbus", label: "Modbus"},
    {key: "comm_protocol_is_mqtt", label: "MQTT"},
    {key: "comm_protocol_is_opc", label: "OPC"},
    {key: "comm_protocol_is_zwave", label: "Z-Wave"},
    {key: "comm_protocol_is_obix", label: "oBIX"},
    {key: "comm_protocol_is_x10", label: "X10"},
    {key: "comm_protocol_is_other", label: "Other"},
];

const ARCHIVED_REASONS=[
    "Added in error",
    "Upgraded",
    "Decommissioned",
];

const HEATING_ELEMENT=[
    {key: "heating_coil_hot_water", label: "Heating Coil - Hot Water"},
    {key: "heating_coil_steam", label: "Heating Coil - Steam"},
    {key: "burners_gas", label: "Burners - Gas"},
    {key: "burners_oil", label: "Burners - Oil"},
];

const COOLING_ELEMENT=[
    {key: "cooling_coil_chilled_water", label: "Cooling Coil - Chilled Water"},
    {key: "compressors", label: "Compressors"},
];

const HUMIDITY_CONTROL=[
    {key: "humidification", label: "Humidification"},
    {key: "dehumidification", label: "Dehumidification"},
];

const AHU_TYPE=[
    {key: "cav", label: "CAV"},
    {key: "vav", label: "VAV"},
];

const OA_CONTROL_MULTI_SELECT=[
    {key: "oa_control_dcv", label: "DCV"},
    {key: "oa_control_dry_bulb_economizing", label: "Dry-Bulb Economizing"},
    {key: "oa_control_enthalpy_economizing", label: "Enthalpy Economizing"},
    {key: "oa_control_no_control", label: "No Control"},
];

const OA_CONTROL_SINGLE_SELECT=[
    {key: "dcv", label: "DCV"},
    {key: "dry_bulb_economizing", label: "Dry-Bulb Economizing"},
    {key: "enthalpy_economizing", label: "Enthalpy Economizing"},
    {key: "no_control", label: "No Control"},
];

const OPERATION_TIMES=[
    {key: "op_times_heating", label: "Heating Season"},
    {key: "op_times_cooling", label: "Cooling Season"},
    {key: "op_times_shoulder", label: "Shoulder Season"},
];

const AC_CHILLER_TYPE=[
    {key: "screw", label: "Screw"},
    {key: "reciprocating", label: "Reciprocating"},
    {key: "scroll", label: "Scroll"},
    {key: "rotary", label: "Rotary"},
    {key: "centrifugal", label: "Centrifugal"},
];

const POINT_BOOLEANS=[
    {key: "point_exists_on_bas", label: "Exists on BAS"},
    {key: "point_currently_trending", label: "Currently trending"},
    {key: "has_a_setpoint", label: "Has Setpoint"},
];

const WC_CHILLER_TYPE=[
    {key: "electric", label: "Electric"},
    {key: "absorption", label: "Absorption"},
];

const HEATING_METHODS=[
    {key: "high_to_lo_pressure_steam", label: "High Pressure to Low Pressure Steam"},
    {key: "steam_to_heat_exch_to_hot_water", label: "Steam to Heat Exchanger to Hot Water"},
];

const FUEL_TYPE=[
    {key: "gas", label: "Gas"},
    {key: "oil", label: "Oil"},
    {key: "dual", label: "Dual"},
];

const COMPRESSOR_FAN_SPEED=[
    {key: "constant", label: "Constant"},
    {key: "high_low", label: "High-Low"},
    {key: "vfd_controlled", label: "VFD Controlled"},
];

const PIPING_CONFIG=[
    {key: "constant_primary_flow_variable_secondary_flow", label: "Constant Primary Flow/Variable Secondary Flow"},
    {key: "constant_primary_flow", label: "Constant Primary Flow"},
    {key: "variable_primary_flow", label: "Variable Primary Flow"},
    {key: "series_flow", label: "Series Flow"},
    {key: "parallel_flow", label: "Parallel Flow"},
    {key: "unknown", label: "Unknown"},
];

const LOOP_CONFIGURATION=[
    {key: "primary", label: "Primary"},
    {key: "secondary", label: "Secondary"},
    {key: "tertiary", label: "Tertiary"},
];

// Note: some labels are overriden in 'Fields[].pointsOptions.options' in the form files
const POINT_MAP=[
    {key: "schedule", label: "Schedule"},
    {key: "heating_mode", label: "Heating Mode"},
    {key: "cooling_mode", label: "Cooling Mode"},
    {key: "economizing_mode", label: "Economizing Mode"},
    {key: "duct_static_pressure", label: "Duct Static Pressure (in. w.c.)"},
    {key: "outside_air_damper_position", label: "Outside Air Damper Position (%)"},
    {key: "outside_air_temperature", label: "Outside Air Temperature (°F)"},
    {key: "outside_air_enthalpy", label: "Outside Air Enthalpy  (BTU/lb)"},
    {key: "mixed_air_temperature", label: "Mixed Air Temperature (°F)"},
    {key: "return_air_damper_position", label: "Return Air Damper Position (%)"},
    {key: "return_air_temperature", label: "Return Air Temperature (°F)"},
    {key: "return_air_enthalpy", label: "Return Air Enthalpy (BTU/lb)"},
    {key: "return_air_relative_humidity", label: "Return Air Relative Humidity (%)"},
    {key: "return_air_dew_point", label: "Return Air Dew Point (°F)"},
    {key: "return_air_co2", label: "Return Air CO2 (PPM)"},
    {key: "economizer_status", label: "Economizer Status"},
    {key: "temperature_after_cooling_coil", label: "Temperature after Cooling Coil (°F)"},
    {key: "temperature_after_compressor", label: "Temperature after Compressor (°F)"},
    {key: "compressor_stage", label: "Compressor Stage"},
    {key: "compressor_status", label: "Compressor Status"},
    {key: "heating_coil_valve_position", label: "Heating Coil Valve Position (%)"},
    {key: "cooling_coil_valve_position", label: "Cooling Coil Valve Position (%)"},
    {key: "temperature_after_heating_coil", label: "Temperature after Heating Coil (°F)"},
    {key: "burner_stage", label: "Burner Stage"},
    {key: "burner_status", label: "Burner Status"},
    {key: "temperature_after_burner", label: "Temperature after Burner (°F)"},
    {key: "discharge_air_temperature", label: "Discharge Air Temperature (°F)"},
    {key: "discharge_air_relative_humidity", label: "Discharge Air Relative Humidity (%)"},
    {key: "discharge_air_dewpoint", label: "Discharge Air Dew Point (°F)"},
    {key: "freeze_stat_temperature", label: "Freeze-Stat Temperature (°F)"},
    {key: "face_damper_position", label: "Face Damper Position (%)"},
    {key: "bypass_damper_position", label: "Bypass Damper Position (%)"},
    {key: "humidification_or_steam_valve_position", label: "Humidification (or Steam) Valve Position (%)"},
    {key: "preheat_coil_valve_position", label: "Preheat Coil Valve Position (%)"},
    {key: "air_flow_rate", label: "Air Flow Rate (CFM)"},
    {key: "speed", label: "Fan Speed (%)"},
    {key: "status", label: "Fan Status"},
    {key: "compressor_speed", label: "Compressor Speed"},
    {key: "fan_speed_command", label: "Fan Speed Command"},
    {key: "fan_speed_feedback", label: "Fan Speed Feedback"},
    {key: "fan_status_command", label: "Fan Status Command"},
    {key: "fan_status_feedback", label: "Fan Status Feedback"},
    {key: "system_status", label: "Perimeter System Status"},
    {key: "electric_reheat_status", label: "Electric Reheat Status"},
    {key: "fan_status", label: "Fan Status"},
    {key: "static_pressure", label: "Static Pressure (in. w.c.)"},
    {key: "zone_temperature", label: "Zone Temperature (°F)"},
    {key: "reheat_valve_signal", label: "Reheat Valve Signal (%)"},
    {key: "tower_fan_status", label: "Cooling Tower Fan Status"},
    {key: "water_supply_temperature", label: "Condenser Water Supply Temperature (°F)"},
    {key: "water_flow_rate", label: "Condenser Water Flow Rate (GPM)"},
    {key: "perimeter_system_status", label: "Perimeter System Status"},
    {key: "perimeter_system_valve_position", label: "Perimeter System Valve Position (%)"},
    {key: "zone_temperature_for_perimeter", label: "Zone Temperature for Perimeter (°F)"},
    {key: "trv_steam_setpoint", label: "TRV Steam Setpoint (°F)"},
    {key: "trv_water_setpoint", label: "TRV Water Setpoint (°F)"},
    {key: "discharge_air_temperature", label: "Energy Recovery Ventilation Discharge Air Temperature (°F)"},
    {key: "ventilation_status", label: "Energy Recovery Ventilation Status"},
    {key: "ventilation_speed", label: "Energy Recovery Ventilation Speed (%)"},
    {key: "exhaust_air_temperature", label: "Energy Recovery Ventilation Exhaust Air Temperature (°F)"},
    {key: "trv_setpoint", label: "TRV Setpoint (°F)"},
    {key: "run_command", label: "Run Command"},
    {key: "oat", label: "Boiler Outside Air Temperature (°F)"},
    {key: "burner_motor_status", label: "Burner Motor Status"},
    {key: "stack_temp", label: "Stack Temperature (°F)"},
    {key: "condensate_return_temperature", label: "Condensate Return Temperature (°F)"},
    {key: "steam_pressure", label: "Steam Pressure (psi)"},
    {key: "stack_temperature", label: "Stack Temperature (°F)"},
];

const VFD_LABEL_MAP=[
    {key: true, label: "Yes"},
    {key: false, label: "No"},
];

const EQUIPMENT_TYPE=[...AHU_TYPE, ...AC_CHILLER_TYPE, ...WC_CHILLER_TYPE];

export {
    EQUIPMENT_CATEGORY_MAP,
    FAMILIARITY_LABEL_MAP,
    DOCUMENT_LABEL_MAP,
    DOCUMENT_TYPE_LABEL_MAP,
    METERINFO_TYPE_LABEL_MAP,
    METERINFO_SOURCE_LABEL_MAP,
    ACCESS_LEVEL_LABEL_MAP,
    COMMUNICATION_PROTOCOLS,
    ARCHIVED_REASONS,
    HEATING_ELEMENT,
    COOLING_ELEMENT,
    HUMIDITY_CONTROL,
    AHU_TYPE,
    OA_CONTROL_MULTI_SELECT,
    OA_CONTROL_SINGLE_SELECT,
    LOOP_CONFIGURATION,
    PIPING_CONFIG,
    COMPRESSOR_FAN_SPEED,
    FUEL_TYPE,
    HEATING_METHODS,
    WC_CHILLER_TYPE,
    AC_CHILLER_TYPE,
    EQUIPMENT_TYPE,
    OPERATION_TIMES,
    POINT_BOOLEANS,
    POINT_MAP,
    VFD_LABEL_MAP,
};

declare global {
    interface Window {
        env: any
    }
}

type EnvType = {
    REACT_APP_AUTH0_CLIENT_ID:string
    REACT_APP_AUTH0_DOMAIN:string
    REACT_APP_AUTH0_AUDIENCE:string
    REACT_APP_AUTH0_SCOPE:string
    REACT_APP_API_BASE_URL:string
    REACT_APP_ENVIRONMENT:string
    REACT_APP_VERSION:string
}

export const env:EnvType={...process.env, ...window.env};

import React, {useState} from "react";
import {UseFormRegister, FieldValues, Controller, Control} from "react-hook-form";
import * as yup from "yup";
import {Search, Clear, SvgIconComponent, CloseOutlined, CheckOutlined} from "@mui/icons-material";
import lo from "lodash";
import {ClickAwayListener} from "@mui/base";
import {
    Button as ButtonBase,
    ButtonProps as ButtonPropsBase,
    Autocomplete as AutocompleteBase,
    AutocompleteProps as AutocompletePropsBase,
    TextField as TextFieldBase,
    TextFieldProps as TextFieldPropsBase,
    Slider as SliderBase,
    SliderProps as sliderPropsBase,
    Typography,
    Box,
    IconButton as IconButtonBase,
    IconButtonProps,
    InputAdornment,
    Switch as SwitchBase,
    FormControlLabel,
    Chip as ChipBase,
    ChipProps as ChipPropsBase,
    Grid,
    GridProps,
    Tooltip,
    InputBaseComponentProps,
} from "@mui/material";
import {TimePicker} from "@mui/x-date-pickers/TimePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFnsV3";
import {PatternFormat, NumericFormatProps} from "react-number-format";
import {resolveFormKey} from "../../handlers";
import {OptionType} from "../../types";

const OPTION_SEPARATOR="-_-";

/**
 * resolveErrorMessage
 * @param {any} errors
 * @param {string} key
 * @return {string|undefined}
 */
const resolveErrorMessage=(errors:any, key:string):string|undefined => {
    let error:string|undefined;
    if (!errors) return error;
    const keyType=resolveFormKey(key);
    if (keyType.isNested && Object.keys(keyType.key)?.length===3) error=errors[keyType.key.root]?.[keyType.key.index]?.[keyType.key.sub]?.message;
    else if (keyType.key) error=errors[keyType.key]?.message;
    return error;
};

/**
 * isRequired
 * @param {yup.Schema} y
 * @return {boolean}
 */
const isRequired=(y:yup.Schema):boolean => !(y.describe().optional);

interface AutocompleteProps extends Omit<AutocompletePropsBase<any, any, any, any>, "onChange" | "renderInput"> {
    onChange:any
    label?:string
    fieldLabel?:boolean
    tooltip?:boolean
    required:boolean
    renderInput?: {
        error?:string
        label?:string
        helperText?:string
    }
}

interface SliderProps extends sliderPropsBase {
    label?: string
    keys: {
        start: string
        end: string
    }
    onChange: any
    value: any
    min: number
    max: number
    valueLabelFormat?: (value: number) => string
}

interface Register {
    register?: UseFormRegister<FieldValues>
}

interface ButtonProps extends ButtonPropsBase {
    label: string
}

interface ChipProps extends ChipPropsBase {
    tooltip?:boolean
}

type TextFieldProps=TextFieldPropsBase & Register & {tooltip?:boolean, maskedFormat?:string, mask?:string};

type ForwardedPatternFormatProps = NumericFormatProps & InputBaseComponentProps;

interface ExportOptions{
    label?:string
    key?:string // possible key override if needed/applicable
    order?:number
 }

// Generic Field type
export interface Field{
    key:string
    label:string
    yup: yup.Schema
    type: "autocomplete"|"slider"|"switch"|"search"|"textfield"|"timepicker"|"label"|"labelselector"|"points"
    autocompleteOptions?:{
        options:string[]|OptionType[]
        default?:string
        helperText?:string
        readOnly?:boolean
        fieldLabel?:boolean
        tooltip?:boolean
    }
    sliderOptions?:{
        keys: {start: string, end: string}
        sliderMin:number
        sliderMax:number
        increment:number
        valueLabelFormat?: (number:number) => string
        default:{building_size_sqft__le: number, building_size_sqft__ge: number} | {year_built__le: number, year_built__ge: number}
        formatValue?:boolean
    },
    switchOptions?: {
        default?:boolean
        disabled?:boolean
        labelPlacement?:"top"|"start"|"bottom"|"end"
    }
    searchOptions?: {
        default:string
        helperText?:string
    }
    textfieldOptions?: {
        default?:any
        disabled?:boolean
        placeholder?:string
        readOnly?:boolean
        fieldLabel?:boolean
        maxLength?:number
        maskedFormat?:string
        mask?:string
        textArea?:{
            multiline:boolean
            rows:number
        }
        endAdornment?:{
            component: (props:any) => React.ReactElement
            onClick?: (value:string) => (_:React.MouseEvent) => void
            children: SvgIconComponent|string
        }
    }
    timepickerOptions?:{
        readOnly?:boolean
        disabled?:boolean
    }
    labelOptions?:{
        default?:string
        tooltip?:boolean
    }
    labelselectorOptions?: {
        options: string[]
        disabled?:boolean
        multi?:boolean
        fieldLabel?:boolean
        fullWidth?:boolean
    },
    pointsOptions?: {
        options:(string|{key:string, label:string})[]
        disabled?:boolean
    },
    exportOptions?:ExportOptions
}

interface ControlledField {
    field:Field
    control:Control<FieldValues>
    errors?:any
}

interface ControlledSearchField extends Omit<ControlledField, "errors">{
    onClear: (args:React.MouseEvent) => void
}

/**
 * Button
 * @param {ButtonProps} props
 * @return {React.ReactElement}
 */
function Button({label, ...props}:ButtonProps): React.ReactElement {
    return (
        <ButtonBase
            sx={{width: "100%"}}
            {...props}
        >
            {label}
        </ButtonBase>
    );
}

/**
 * Chip
 * @param {ChipProps} ChipProps
 * @return {React.ReactElement}
 */
function Chip({tooltip, ...props}:ChipProps): React.ReactElement {
    return (
        tooltip
            ?(
                <Tooltip
                    title={props.label}
                    slotProps={{
                        popper: {
                            modifiers: [{
                                name: "offset",
                                options: {
                                // aligns the tooltip's y-axis with the chip
                                    offset: [0, -11],
                                },
                            },
                            ],
                        },
                    }}
                >
                    <ChipBase {...props} />
                </Tooltip>
            )
            : (
                <ChipBase {...props} />
            )

    );
}

/**
 * IconButton
 * @param {IconButtonProps} props
 * @return {React.ReactElement}
 */
function IconButton(props:IconButtonProps):React.ReactElement {
    return (<IconButtonBase {...props}>{props.children}</IconButtonBase>);
}

const ForwardedPatternFormat = React.forwardRef<HTMLElement, ForwardedPatternFormatProps>(
    ({onChange, format, ...rest}, ref) => (
        <PatternFormat
            format={format}
            {...rest}
            getInputRef={ref}
            onValueChange={(v:any, e:any) => { if (onChange) onChange(v.value); }}
        />
    ),
);

/**
 * TextField
 * @param {TextFieldProps} props
 * @return {React.ReactElement}
 */
function TextField({register, tooltip, maskedFormat, mask, ...props}:TextFieldProps): React.ReactElement {
    const textFieldBaseProps={...props, sx: {width: "100%"}, ...register};
    const content = maskedFormat ? (
        <TextFieldBase
            {...textFieldBaseProps}
            InputProps={{
                inputComponent: ForwardedPatternFormat,
                ...textFieldBaseProps.InputProps,
                inputProps: {
                    allowEmptyFormatting: true,
                    valueIsNumericString: true,
                    format: maskedFormat,
                    mask,
                },
            }}
        />
    ) :<TextFieldBase {...textFieldBaseProps} />;

    return (
        tooltip
            ?(
                <Tooltip
                    title={props.inputProps?.value}
                    slotProps={{
                        popper: {
                            modifiers: [{
                                name: "offset",
                                options: {
                                    // aligns the tooltip's y-axis with the input field
                                    offset: [0, -35],
                                },
                            },
                            ],
                        },
                    }}
                >
                    {content}
                </Tooltip>
            )
            : content
    );
}

/**
 * Autocomplete
 * @param {AutocompleteProps} props
 * @return {React.ReactElement}
 */
function Autocomplete(props:AutocompleteProps): React.ReactElement {
    const [isFocus, setIsFocus]=useState(false);
    const {fieldLabel, tooltip, ...rest}=props;
    const [open, setOpen]=useState(false);

    /**
     * onFocus
     * @param {React.FocusEvent} args
     */
    const onFocus=(args:React.FocusEvent):void => setIsFocus(true);

    /**
     * onBlur
     * @param {React.FocusEvent} args
     */
    const onBlur=(args:React.FocusEvent):void => setIsFocus(false);

    /**
     * renderInput
     * @param {TextFieldProps} params
     * @return {React.ReactElement}
     */
    const renderInput=(params:TextFieldProps):React.ReactElement => {
        // resolve helper text;
        let helperText=" ";
        if (props.renderInput?.error) helperText=props.renderInput.error;
        else if (props.renderInput?.helperText) helperText=props.renderInput?.helperText;
        // resolve label type via fieldLabel flag
        let label="Select...";
        if ((isFocus || props.value!==null) && fieldLabel!==true) label="";
        else if (fieldLabel===true && props.label) label=props.label;
        // parse input value
        const v=params?.inputProps?.value.split(OPTION_SEPARATOR);

        return (
            <ClickAwayListener onClickAway={():void => setOpen(false)}>
                <Box>
                    <TextField
                        color="secondary"
                        {...params}
                        inputProps={v.length>=2?{...params.inputProps, value: "", sx: {display: "none"}}:params.inputProps}
                        InputProps={
                            v.length>=2
                                ?({
                                    ...params.InputProps,
                                    startAdornment: (
                                        <Grid onClick={(args:React.MouseEvent):void => setOpen(true)} container direction="row" justifyContent="flex-start" alignItems="center" spacing={2} sx={{margin: "0px"}}>
                                            {v[0] && <Grid item sx={{paddingLeft: "10px !important", paddingTop: "8px !important", paddingBottom: "8px !important"}}>{v[0]}</Grid>}
                                            {v[1] && <Grid item sx={{paddingTop: "3px !important", paddingBottom: "3px !important"}}><Chip label={v[1]} /></Grid>}
                                            {v[2] && <Grid item sx={{paddingTop: "3px !important", paddingBottom: "3px !important"}}><Chip label={v[2]} /></Grid>}
                                        </Grid>
                                    ),
                                })
                                :params.InputProps
                        }
                        tooltip={tooltip}
                        helperText={helperText}
                        error={props.renderInput?.error!==undefined}
                        label={label}
                        onFocus={fieldLabel!==true?onFocus:undefined}
                        onBlur={fieldLabel!==true?onBlur:undefined}
                    />
                </Box>
            </ClickAwayListener>
        );
    };

    return (
        <Box>
            {/* Label */}
            {fieldLabel!==true && (
                <Typography sx={{display: "flex"}} component="div" color="secondary.light">
                    {props.label}
                    {props.required && <Box sx={{marginLeft: "4px", color: "#FF0000"}}>*</Box>}
                </Typography>
            )}
            <AutocompleteBase
                {...rest}
                open={open}
                onClose={(args:React.SyntheticEvent):void => setOpen(false)}
                onOpen={(args:React.SyntheticEvent):void => setOpen(true)}
                renderInput={renderInput}
            />
        </Box>
    );
}

/**
 * Slider
 * @param {SliderProps} SliderProps
 * @return {React.ReactElement}
 */
function Slider(props:SliderProps): React.ReactElement {
    return (
        <Box>
            {/* Label */}
            <Typography color="secondary.light" marginBottom={4}>{props.label}</Typography>
            {/* Slider */}
            <SliderBase
                sx={{width: "99%"}}
                color="secondary"
                {...props}
                onChange={(e, data) => {
                    props.onChange({[props.keys.start]: (data as number[])[0], [props.keys.end]: (data as number[])[1]});
                }}
                value={[props.value[props.keys.start], props.value[props.keys.end]]}
            />
            {/* Min max range label */}
            <Box justifyContent="space-between" display="flex">
                {[[props.min, props.keys.start], [props.max, props.keys.end]].map(([value, key]) => (
                    <Typography key={key} fontSize={14} color="text.secondary">
                        {props.valueLabelFormat? props.valueLabelFormat(value as number): value}
                    </Typography>
                ))}
            </Box>
        </Box>

    );
}

/**
 * ControlledSlider
 * @param {ControlledField} props
 * @return {React.ReactElement}
 */
function ControlledSlider({field: {sliderOptions, ...args}, control, ...props}:Omit<ControlledField, "errors">): React.ReactElement|null {
    if (sliderOptions===undefined) return null;
    return (
        <Controller
            name={args.key}
            defaultValue={sliderOptions.default}
            control={control}
            render={
                ({field: {onChange, value}}) => (
                    <Slider
                        {...props}
                        keys={sliderOptions.keys}
                        valueLabelFormat={sliderOptions.valueLabelFormat}
                        label={args.label}
                        value={value}
                        min={sliderOptions.sliderMin}
                        max={sliderOptions.sliderMax}
                        onChange={onChange}
                        step={sliderOptions.increment}
                        valueLabelDisplay="on"
                    />
                )
            }
        />
    );
}

/**
 * ControlledAutocomplete
 * @param {ControlledAutocompleteProps} props
 * @return {React.ReactElement}
 */
function ControlledAutocomplete({field: {autocompleteOptions, ...args}, control, errors, ...props}:ControlledField): React.ReactElement|null {
    if (autocompleteOptions===undefined) return null;
    const error=resolveErrorMessage(errors, args.key);
    /**
     * renderOption
     * @param {any} renderOptionProps
     * @param {string|OptionType} option
     * @return {React.ReactElement}
     */
    const renderOption=(renderOptionProps:any, option:string|OptionType):React.ReactElement => {
        if (typeof option==="string") return <Box component="li" {...renderOptionProps}>{option}</Box>;
        return (
            <Box component="li" {...renderOptionProps}>
                {option?.primary}
                <Chip sx={{marginLeft: "9px"}} label={option?.secondary} />
                {option?.tertiary && <Chip sx={{marginLeft: "9px"}} label={option?.tertiary} />}
            </Box>
        );
    };

    const sortedOptions=autocompleteOptions?.options.sort((a:any, b:any) => {
        if (typeof a==="string" && typeof b==="string") return a.localeCompare(b);
        return a.primary.localeCompare(b.primary) || a.secondary.localeCompare(b.secondary);
    });

    return (
        <Controller
            name={args.key}
            defaultValue={autocompleteOptions.default||null}
            control={control}
            render={
                ({field: {onChange, value}}) => (
                    <Autocomplete
                        {...props}
                        label={args.label}
                        fieldLabel={autocompleteOptions.fieldLabel}
                        required={isRequired(args.yup)}
                        onChange={(_:React.ChangeEvent, data:any) => onChange(data)}
                        value={value}
                        tooltip={autocompleteOptions.tooltip}
                        options={sortedOptions||[]}
                        readOnly={autocompleteOptions.readOnly||false}
                        // renderInput prop type is overriden -- see above type
                        renderInput={{error, label: args.label, helperText: autocompleteOptions.helperText}}
                        getOptionLabel={(option:string|OptionType) => {
                            if (typeof option==="string") return option;
                            return `${option?.primary}${OPTION_SEPARATOR}${option?.secondary}${option?.tertiary?`${OPTION_SEPARATOR}${option?.tertiary}`:""}`;
                        }}
                        renderOption={renderOption}
                        isOptionEqualToValue={(option:string|OptionType, v:string|OptionType) => (lo.isEqual(option, v))}
                    />
                )
            }
        />
    );
}

/**
 * ControlledSwitch
 * @param {ControlledField} props
 * @return {React.ReactElement}
 */
function ControlledSwitch({field: {switchOptions, ...args}, control, ...props}:ControlledField): React.ReactElement|null {
    return (
        <Controller
            name={args.key}
            defaultValue={switchOptions?.default||false}
            control={control}
            render={
                ({field: {onChange, value}}) => (
                    <Box>
                        <FormControlLabel
                            sx={{
                                margin: 0,
                                ...(!["end", "start"].includes(switchOptions?.labelPlacement||"")) && {alignItems: "baseline"},
                            }}
                            componentsProps={{
                                typography: {
                                    color: "secondary.light",
                                    sx: {
                                        marginBottom: "-10px",
                                    },
                                },
                            }}
                            labelPlacement={switchOptions?.labelPlacement||"top"}
                            label={args.label}
                            disabled={switchOptions?.disabled||false}
                            control={(
                                <SwitchBase
                                    sx={{
                                        ...(["end", "start"].includes(switchOptions?.labelPlacement||""))?{marginTop: "8px"}:{marginTop: "6px"},
                                        ...(switchOptions?.labelPlacement!=="start") && {left: "-8px"},
                                    }}
                                    checked={value}
                                    color="primary"
                                    onChange={(_:React.ChangeEvent<HTMLInputElement>) => onChange(_.target.checked)}
                                />
                            )}
                        />
                    </Box>
                )
            }
        />
    );
}

/**
 * ControlledSearch
 * @param {ControlledSearchField} props
 * @return {React.ReactElement}
 */
function ControlledSearch({field: {searchOptions, ...args}, control, onClear, ...props}:ControlledSearchField): React.ReactElement|null {
    return (
        <Controller
            name={args.key}
            defaultValue={searchOptions!.default}
            control={control}
            render={
                ({field: {onChange, value}}) => (
                    <TextField
                        {...props}
                        placeholder={args.label}
                        helperText={searchOptions?.helperText}
                        color="secondary"
                        autoComplete="off" // style issue occur when an item is choosen from the autocomplete dropdown
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {/* Clear Icon Button */}
                                    {value
                                        ?(
                                            <IconButton
                                                onClick={(_:React.MouseEvent) => {
                                                    onChange("");
                                                    onClear(_);
                                                }}
                                            >
                                                <Clear />
                                            </IconButton>
                                        )
                                        :<Box sx={{width: "40px"}} />}
                                    {/* Search Icon Button */}
                                    <IconButton type="submit"><Search /></IconButton>
                                </InputAdornment>
                            ),
                        }}
                        value={value}
                        onChange={(_: React.ChangeEvent<HTMLInputElement>) => onChange(_.target.value)}
                    />
                )
            }
        />
    );
}

/**
 * ControlledTextField
 * @param {ControlledField} props
 * @return {React.ReactElement}
 */
function ControlledTextField({field: {textfieldOptions, ...args}, control, errors, ...props}: ControlledField): React.ReactElement {
    const error=resolveErrorMessage(errors, args.key);
    const endAdornment=textfieldOptions?.endAdornment;

    return (
        <Controller
            name={args.key}
            control={control}
            defaultValue={textfieldOptions?.default||""}
            render={
                ({field: {value, onChange}}) => (
                    <Box>
                        {args.label && textfieldOptions?.fieldLabel!==true && (
                            <Typography sx={{display: "flex"}} component="div" color="secondary.light">
                                {args.label}
                                {isRequired(args.yup) && <Box sx={{marginLeft: "4px", color: "#FF0000"}}>*</Box>}
                            </Typography>
                        )}
                        <TextField
                            {...props}
                            {...textfieldOptions?.maxLength && {inputProps: {maxLength: textfieldOptions?.maxLength}}}
                            label={textfieldOptions?.fieldLabel===true?args.label:undefined}
                            disabled={textfieldOptions?.disabled}
                            onChange={(_:any) => onChange(_)}
                            placeholder={textfieldOptions?.placeholder||undefined}
                            color="secondary"
                            value={value}
                            helperText={(error||" ")}
                            error={(error!==undefined)}
                            InputProps={{
                                readOnly: textfieldOptions?.readOnly,
                                ...endAdornment && ({endAdornment: (
                                    <InputAdornment position="end">
                                        <endAdornment.component
                                            onClick={endAdornment.onClick && endAdornment.onClick(value)}
                                        >
                                            {typeof endAdornment.children==="string"
                                                ?<Typography variant="body1">{endAdornment.children}</Typography>
                                                :<endAdornment.children />}
                                        </endAdornment.component>
                                    </InputAdornment>
                                )}),
                            }}
                            {...textfieldOptions?.textArea}
                            sx={{
                                width: "100%",
                                ...textfieldOptions?.disabled && {".MuiInputBase-input": {
                                    backgroundColor: "#333B390A",
                                }},
                            }}
                            {...textfieldOptions?.maskedFormat && {maskedFormat: textfieldOptions?.maskedFormat, mask: textfieldOptions?.mask}}
                        />
                    </Box>
                )
            }
        />
    );
}

/**
 * ControlledTimePicker
 * @param {ControlledField} props
 * @return {React.ReactElement}
 */
function ControlledTimePicker(props:ControlledField):React.ReactElement {
    return (
        <Controller
            name={props.field.key}
            defaultValue={null}
            control={props.control}
            render={
                ({field: {onChange, value}}) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <TimePicker
                            value={value}
                            onChange={(_:any, data:any) => onChange(_)}
                            views={["hours", "minutes"]}
                            label={props.field.label}
                            readOnly={props.field.timepickerOptions?.readOnly||false}
                            disabled={props.field.timepickerOptions?.disabled||false}
                            slotProps={{textField: {variant: "outlined", error: props.errors[props.field.key]?.message?true:false as boolean, helperText: props.errors[props.field.key]?.message||" "}}}
                        />
                    </LocalizationProvider>
                )
            }
        />
    );
}

/**
 * ControlledLabel
 * @param {Omit<ControlledField, "errors">} props
 * @return {React.ReactElement}
 */
function ControlledLabel(props:Omit<ControlledField, "errors">):React.ReactElement {
    return (
        <Controller
            name={props.field.key}
            defaultValue={props.field.labelOptions?.default}
            control={props.control}
            render={
                ({field: {onChange, value}}) => (
                    <Chip label={value||props.field.labelOptions?.default} tooltip={props.field.labelOptions?.tooltip} />
                )
            }
        />
    );
}

/**
 * ControlledLabelSelector
 * @param {Omit<ControlledField, "errors">} props
 * @return {React.ReactElement}
 */
function ControlledLabelSelector(props:Omit<ControlledField, "errors">):React.ReactElement {
    const options:string[]=props.field.labelselectorOptions?.options as string[];
    let gridProps:GridProps={direction: "row", justifyContent: "flex-start", alignItems: "flex-start", spacing: 1};
    if (props.field.labelselectorOptions?.fullWidth) gridProps={direction: "row", justifyContent: "space-around", alignItems: "flex-start", spacing: 1};
    return (
        <Controller
            name={props.field.key}
            defaultValue={props.field.labelselectorOptions?.multi? []: ""}
            control={props.control}
            render={
                ({field: {onChange, value}}) => (
                    <Box>
                        {props.field.labelselectorOptions?.fieldLabel!==true && <Typography color="secondary.light" paddingBottom={1.25}>{props.field.label}</Typography>}
                        <Grid container {...gridProps}>
                            {options.map((option) => {
                                const isSelected = props.field.labelselectorOptions?.multi? value.includes(option): value===option;
                                return (
                                    <Grid item key={option} {...props.field.labelselectorOptions?.fullWidth && {xs: Math.round(12/options.length)}}>
                                        <Chip
                                            sx={{...(props.field.labelselectorOptions?.fullWidth && {width: "100%"})}}
                                            disabled={props.field.labelselectorOptions?.disabled}
                                            color={isSelected?"primary":"secondary"}
                                            variant={isSelected?"filled":"outlined"}
                                            label={option}
                                            // Note: 'onDelete' is needed to render 'deleteIcon'
                                            onDelete={() => {}}
                                            deleteIcon={isSelected?<CheckOutlined />:<CloseOutlined />}
                                            onClick={() => {
                                                if (props.field.labelselectorOptions?.multi) {
                                                    const newValue = isSelected?value.filter((v:any) => v!==option):[...value, option];
                                                    onChange(newValue);
                                                } else {
                                                    onChange(value===option?null:option);
                                                }
                                            }}
                                        />
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Box>
                )
            }
        />
    );
}

TextField.defaultProps = {
    register: undefined,
    tooltip: undefined,
    maskedFormat: undefined,
    mask: undefined,
};

Slider.defaultProps = {
    label: undefined,
    valueLabelFormat: undefined,
};

Autocomplete.defaultProps = {
    renderInput: undefined,
    label: undefined,
    fieldLabel: undefined,
    tooltip: undefined,
};

Chip.defaultProps = {
    tooltip: undefined,
};

ControlledAutocomplete.defaultProps = {
    errors: undefined,
};

ControlledSwitch.defaultProps = {
    errors: undefined,
};

ControlledTextField.defaultProps = {
    errors: undefined,
};

ControlledTimePicker.defaultProps = {
    errors: undefined,
};

export {
    ControlledSlider,
    ControlledAutocomplete,
    Button,
    IconButton,
    Chip,
    ControlledSwitch,
    ControlledSearch,
    ControlledTextField,
    ControlledTimePicker,
    ControlledLabel,
    ControlledLabelSelector,
};

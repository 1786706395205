import {ColInfo, utils, WorkBook, WorkSheet, writeFileXLSX} from "xlsx";
import {AxiosResponse} from "axios";
import {EQUIPMENTS} from "../components/forms";
import {
    AIR_HANDLING_FIELDS,
    BAS_FIELDS,
    COMPONENT_FIELDS,
    COOLING_FIELDS,
    DOCUMENTS_FIELDS,
    HEATING_FIELDS,
    KEYS,
    PROPERTY_FIELDS,
    REAL_TIME_METER_FIELDS,
    SYSTEM_KEYS,
    TERMINAL_UNITS_FIELDS} from "../config/inventoryExportKey";
import {EQUIPMENT_CATEGORY_MAP} from "../config";
import * as columnMap from "../config/columnMap";
import {Field} from "../components/generics/inputs";

interface SheetType{
    data:any
    fields:Field[]
    title:string
}

// Merge all of the columnMaps
const COLUMN_MAP_DATA:any = [];
Object.keys(columnMap).forEach((x:any) => COLUMN_MAP_DATA.push(columnMap[x as keyof typeof columnMap]));
const COLUMN_MAP = COLUMN_MAP_DATA.flat().filter((x:any) => Object.keys(x).includes("key"));

/**
 * getColumnMap
 * @param {string} key
 * @return {any}
 */
const getColumnMap = (key:string):any => COLUMN_MAP.find((x:any) => x.key === key);

/**
 * convertDataToJson
 * @param {any} data
 * @param {Field[]} fields
 * @param {any} row
 * @param {string} itemKey?
 * @return {Record<string, any>}
 */
const convertDataToJson = (data:any, fields:Field[], row:any, itemKey?:string):Record<string, any> => {
    const JSON:Record<string, any> = {Property_BDBID: row.id};

    if (SYSTEM_KEYS.includes(itemKey)) {
        const categoryKey = EQUIPMENTS.find((x:any) => x.key === itemKey)?.category;
        const categoryLabel = EQUIPMENT_CATEGORY_MAP.find((x:any) => x.category === categoryKey)?.label;
        JSON.Category = categoryLabel;
    }

    fields.forEach((field:any) => {
        let value = data[field.key];

        if (getColumnMap(data[field.key])) value = getColumnMap(data[field.key]).label;

        if (field.key === "operation_times") {
            value = "";
            const OP_KEYS = columnMap.OPERATION_TIMES.map((x:any) => x.key);
            for (let i = 0; i < OP_KEYS.length; i += 1) {
                const {label} = getColumnMap(OP_KEYS[i]);
                if (data[OP_KEYS[i]]) {
                    value += `${value ? ", " : ""}${label}`;
                }
            }
        }

        JSON[`${field.exportOptions.label ? field.exportOptions.label : field.label}`] = value;
    });
    if (data.created_on) JSON["Created On"] = new Date(data.created_on).toISOString().replace("T", " ").substring(0, 16);
    if (data.last_updated_on) JSON["Last Updated On"] = new Date(data.last_updated_on).toISOString().replace("T", " ").substring(0, 16);
    return JSON;
};

/**
* convertCollectionToJson
* @param {any} collection
* @param {Field[]} fields
* @param {any} row
* @return {[]|null}
*/
const convertCollectionToJson = (collection:any, fields:Field[], row?:any):[]|null => {
    if (!collection) return null;
    const COLLECTION_ARRAY:any = [];
    Object.keys(collection).forEach((item:any) => {
        collection[item]?.forEach((collectionData:any) => COLLECTION_ARRAY.push(convertDataToJson(collectionData, fields, row, item)));
    });
    return COLLECTION_ARRAY;
};

/**
* convertJsonToExcelSheet
* @param {any} jsonData
* @param {Field[]} fields
* @param {any} row
* @return {Worksheet|null}
*/
const convertJsonToExcelSheet = (jsonData:any, fields:Field[], row:any):WorkSheet|null => {
    let excelSheet;
    if (Array.isArray(jsonData)) excelSheet = jsonData.map((x:any) => convertDataToJson(x, fields, row));
    else excelSheet = convertCollectionToJson(jsonData, fields, row);
    if (excelSheet && excelSheet.length) {
        return utils.json_to_sheet(excelSheet);
    }
    return null;
};

/**
* collectAndResolvePoints
* @param {any} data
* @param {any} row
* @return {any}
*/
const collectAndResolvePoints = (data:any, row:any):any => {
    const totalResult:any=[];
    Object.keys(data).forEach((key:string) => {
        // Exit if not a system or a component
        if (![...SYSTEM_KEYS].includes(key) && !(key in KEYS.components)) return;

        // If an array, and not null -- proceed:
        if (Array.isArray(data[key])) {
            let systemField:any = EQUIPMENTS.find((equipment:any) => equipment.key === key);

            // Loop through the systems or components array:
            data[key].forEach((item:any) => {
                // Set system, which will also be used as a conditional later:
                const system = systemField ? data[key][0] : data[KEYS.components[key]][0];

                let componentName:string|null = null;
                let component:any|null = null;

                // If the iteration is not a system, (it must be a component).
                // Store the component (item:any), in variable `component`.
                // Fetch the systemField using data from the component.
                if (!systemField) {
                    component = item;
                    systemField = EQUIPMENTS.find((equipment:any) => equipment.key === KEYS.components[key]);
                }

                if (component) {
                    if (Array.isArray(systemField.component)) {
                        componentName = systemField.component.find((x:any) => x.variant.key === component.variant).variant.label;
                    } else {
                        componentName = systemField.component.label;
                    }
                }

                if (item.points && Object.keys(item.points).length > 0) {
                    Object.keys(item.points).forEach((point:any) => {
                        // TODO: Figure out a programmatic solution
                        // to loop, map, and output the object below
                        // without stricly typing key/labels.

                        const p:Record<string, any> = {
                            Property_BDBID: row.id,
                            BAS_Software: undefined, // Note: Not found in the data.

                            "System ID/Tag": system.tag_id,
                            System: systemField.label,
                            "System Name": system.name,

                            "Component ID/Tag": component?.tag_id,
                            Component: componentName,
                            "Component Name": component?.name,

                            "Point Name": getColumnMap(point)?.label,
                            "Point Tag/ID": item.points[point].tag_id,
                            "Exists in BAS": getColumnMap(item.points[point].point_exists_on_bas)?.label,
                            "Currently Trending": getColumnMap(item.points[point].point_currently_trending)?.label,
                            "Has Setpoint": getColumnMap(item.points[point].has_a_setpoint)?.label,
                            Note: point.notes,
                        };
                        totalResult.push(p);
                    });
                }
            });
        }
    });
    return totalResult;
};

/**
* buildCollection
* @param {any} data
* @param {string[]} keys
* @return {Record<string, number>}
* */
const buildCollection = (data:any, keys:string[]):Record<string, number> => Object.keys(data).filter((key) => keys.includes(key))
    .reduce((obj, key) => {
        const filtered:Record<string, number> = obj;
        filtered[key] = data[key];
        return filtered;
    }, {});

/**
* @param {WorkSheet} sheet
* @return {string[]}
* getSheetHeaders
* https://github.com/SheetJS/sheetjs/issues/214#issuecomment-1146229725
* */
const getSheetHeaders = (sheet:WorkSheet):string[] => {
    // eslint-disable-next-line prefer-regex-literals
    const headerRegex = new RegExp("^([A-Za-z]+)1='(.*)$");
    const cells:string[]= utils.sheet_to_formulae(sheet);
    return cells.filter((item) => headerRegex.test(item)).map((item) => item.split("='")[1]);
};

/**
* @param {WorkSheet} worksheet
* @return {WorkSheet}
* autoFitColumns
* */
const autoFitColumns = (worksheet:WorkSheet):WorkSheet => {
    const headers = getSheetHeaders(worksheet);
    const objectMaxLength:ColInfo[] = [];
    headers.forEach((header:any) => objectMaxLength.push({width: header.length}));
    // eslint-disable-next-line no-param-reassign
    worksheet["!cols"] = objectMaxLength;
    return worksheet;
};

/**
* addSheetToWorkbook
* @param {Workbook} wb
* @param {WorkSheet|null} sheet
* @param {string} title
* @return {void}
*/
const addSheetToWorkbook = (wb:WorkBook, sheet:WorkSheet|null, title:string):void => {
    if (sheet) {
        utils.book_append_sheet(wb, autoFitColumns(sheet), title);
    } else {
        // We don't want to throw an error, as we want the workbook to
        // still be created, and ignore said sheet.
        // console.log(`No data available to add a sheet for ${title}.`);
    }
};

/**
* buildSheets
* @param {SheetType[]} sheets
* @param {any} row
* @return {WorkBook}
*/
const buildWorkbook = (sheets:SheetType[], row:any):WorkBook => {
    const wb:WorkBook = utils.book_new();
    sheets.forEach((item:SheetType) => {
        let sheet;
        if (item.fields) sheet = convertJsonToExcelSheet(item.data, item.fields, row);
        else sheet = item.data.length? utils.json_to_sheet(item.data) : null;
        addSheetToWorkbook(wb, sheet, item.title);
    });
    return wb;
};

/**
* singlePropertyExport
* @param {any} row
* @param {any} payload
* @return {WorkBook}
*/
const constructSinglePropertyExport=(row:any, payload:AxiosResponse):WorkBook|null => {
    if (payload.status === 200) {
        try {
            const {data} = payload;
            const SHEETS = [
                {data: [row], fields: PROPERTY_FIELDS, title: "Property"},
                {data: data.meterinfo, fields: REAL_TIME_METER_FIELDS, title: "Real-time Meters"},
                {data: data.propertydocument, fields: DOCUMENTS_FIELDS, title: "Documents"},
                {data: data.bas, fields: BAS_FIELDS, title: "Building Automation Systems"},
                {data: buildCollection(data, KEYS.heating.keys), fields: HEATING_FIELDS, title: "Heating"},
                {data: buildCollection(data, KEYS.cooling.keys), fields: COOLING_FIELDS, title: "Cooling"},
                {data: buildCollection(data, KEYS.airhandling.keys), fields: AIR_HANDLING_FIELDS, title: "Air Handling"},
                {data: buildCollection(data, KEYS.terminal.keys), fields: TERMINAL_UNITS_FIELDS, title: "Terminal Units"},
                {data: buildCollection(data, Object.keys(KEYS.components)), fields: COMPONENT_FIELDS, title: "Components"},
                {data: collectAndResolvePoints(data, row), fields: null, title: "Points"},
            ];
            return buildWorkbook(SHEETS, row);
        } catch (e) {
            return null;
        }
    } else {
        return null;
    }
};

/**
 * constructFileXLSX
 * @param {Workbook} data
 * @param {string} fileName
 * @return {void}
 */
const constructFileXLSX = (data:WorkBook, fileName:string):void => {
    writeFileXLSX(data, fileName);
};

export {
    constructSinglePropertyExport,
    constructFileXLSX,
};

import React from "react";
import * as yup from "yup";
import {useFormContext} from "react-hook-form";
import {populateValues} from "../../../handlers";
import {Field} from "../../generics/inputs";
import {constructLayout} from "./aux";

interface RemoteAccessProps{
    fields:Field[]
    controllingFields:any
    accessLevel:string[]
}

// NOTE: fields "ORDER" matters; see controllingFields under BasDetails
export const Fields:Field[] = [
    {
        key: "remote_access_capability",
        label: "Remote access capability?",
        yup: yup.mixed().nullable(),
        type: "switch",
        switchOptions: {
            disabled: true,
        },
        exportOptions: {
            label: "BAS_Is there remote access capability?",
            order: 10,
        },
    },
    {
        key: "level_of_access",
        label: "Level of access",
        yup: yup.mixed().nullable(),
        type: "autocomplete",
        autocompleteOptions: {
            options: [],
            readOnly: true,
        },
        exportOptions: {
            label: "BAS_Level of access",
            order: 12,
        },
    },
    {
        key: "remote_access_type",
        label: "Remote access type",
        yup: yup.string(),
        type: "textfield",
        textfieldOptions: {
            readOnly: true,
        },
        exportOptions: {
            label: "BAS_Remote access type",
            order: 11,
        },
    },
    {
        key: "remote_access_notes",
        label: "Notes",
        yup: yup.string(),
        type: "textfield",
        textfieldOptions: {
            readOnly: true,
            maxLength: 1000,
            textArea: {
                multiline: true,
                rows: 13,
            },
        },
        exportOptions: {
            label: "BAS_Level of access_Notes",
            order: 13,
        },
    },
];

/**
 * RemoteAccess
 * @param {RemoteAccessProps} props
 * @return {React.ReactElement}
 */
function RemoteAccess(props:RemoteAccessProps):React.ReactElement {
    const methods:any=useFormContext();
    // set field options
    populateValues(props.accessLevel, props.fields, "level_of_access");

    return (
        constructLayout({
            fields: props.fields,
            methods,
            controllingFields: props.controllingFields,
            controllingKey: "remote_access_capability",
            textAreaRows: {min: 1, max: 13},
            spacing: {min: 0, max: 7.1},
        })
    );
}

export default RemoteAccess;

import {env} from "./env";

export const BASE_ROUTE:string=env.REACT_APP_API_BASE_URL;
export const ORGANIZATION_ROUTE=`${BASE_ROUTE}/v1/organization/`;
export const PRIMARY_FUNCTION_ROUTE=`${BASE_ROUTE}/v1/property/primaryfunction/`;
export const PROPERTY_ROUTE=`${BASE_ROUTE}/v1/property/`;
export const PROPERTY_ID_ROUTE=`${BASE_ROUTE}/v1/property/{id}`;
export const PROPERTY_ID_BAS=`${BASE_ROUTE}/v1/property/{id}/bas/`;
export const PROPERTY_ID_EQUIPMENT=`${BASE_ROUTE}/v1/property/{id}/equipment`;
export const PROPERTY_ID_INVENTORY=`${BASE_ROUTE}/v1/property/{id}/inventory`;
export const PROPERTY_CAMPUS_ID_EQUIPMENT=`${BASE_ROUTE}/v1/property/campus/{id}/equipment/`;
export const PROPERTY_CAMPUS_ID_PROPERTIES=`${BASE_ROUTE}/v1/property/campus/{id}/properties/`;
export const PROPERTY_DOCUMENT_ROUTE=`${BASE_ROUTE}/v1/property/{id}/document/`;
export const PROPERTY_METERINFO_ROUTE=`${BASE_ROUTE}/v1/property/{id}/meterinfo/`;
export const CHANGE_LOG=`${BASE_ROUTE}/v1/changelog/`;
export const BAS_ID=`${BASE_ROUTE}/v1/bas/{id}`;
export const EQUIPMENT_BASE=`${BASE_ROUTE}/v1/`;
export const SLACK_ROUTE=`${BASE_ROUTE}/slack/`;

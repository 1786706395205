import React, {useEffect, useRef, memo} from "react";
import {Grid} from "@mui/material";
import {useForm, UseFormReturn} from "react-hook-form";
import * as yup from "yup";
import {AxiosResponse} from "axios";
import {populateValues, resolvePoints} from "../../../../handlers";
import {ControlledAutocomplete, ControlledLabelSelector, ControlledTextField, ControlledSwitch, Field} from "../../../generics/inputs";
import PointsGrid from "../../../layout/PointsGrid";
import {OPERATION_TIMES, API_PRIMARY_FIELD_REGEX, BAS_ARCHIVED_LABEL} from "../../../../config";
import {DialogType, OptionType} from "../../../../types";
import {COMMON_FIELDS} from "../../common/Fields";

interface BaseProps{
    fields:Field[]
    bases:AxiosResponse|null
    form?:UseFormReturn // eslint-disable-line react/require-default-props
    values?:AxiosResponse|null // eslint-disable-line react/require-default-props
    dialog?:DialogType // eslint-disable-line react/require-default-props, react/no-unused-prop-types
}

export const Fields:Field[] = [
    {
        key: "name",
        label: "Component Name",
        yup: yup.string().matches(API_PRIMARY_FIELD_REGEX.regex, {message: API_PRIMARY_FIELD_REGEX.message}).required("Required"),
        type: "textfield",
        textfieldOptions: {
            readOnly: true,
        },
        exportOptions: {order: 6},
    },
    {
        key: "vfd",
        label: "VFD?",
        yup: yup.mixed().nullable(),
        type: "switch",
        switchOptions: {
            disabled: true,
        },
        exportOptions: {order: 7},
    },
    {
        key: "tag_id",
        label: "Component ID/Tag",
        yup: yup.string().matches(API_PRIMARY_FIELD_REGEX.regex, {message: API_PRIMARY_FIELD_REGEX.message}).required("Required"),
        type: "textfield",
        textfieldOptions: {
            readOnly: true,
        },
        exportOptions: {order: 4},
    },
    COMMON_FIELDS.bas_version,
    {
        key: "operation_times",
        label: "Operation Times",
        yup: yup.mixed().nullable(),
        type: "labelselector",
        labelselectorOptions: {
            options: [],
            disabled: true,
            multi: true,
        },
        exportOptions: {order: 8},
    },
    {
        key: "notes",
        label: "Notes",
        yup: yup.string(),
        type: "textfield",
        textfieldOptions: {
            readOnly: true,
            maxLength: 800,
            textArea: {
                multiline: true,
                rows: 10,
            },
        },
        exportOptions: {order: 9},
    },
];

/**
 * resolveValues
 * @param {any} values
 * @param {fields} fields
 * @param {AxiosResponse|null} bases
 */
export const resolveValues=(values:any, fields:Field[], bases:AxiosResponse|null):any => {
    const obj:any={};
    const bas=bases?.data.results.find((i:any) => i.id===values.bas_id);

    fields.forEach((field:Field) => {
        if (field.key==="points") obj.points=resolvePoints(values.points);
        else if (field.key in values) obj[field.key]=values[field.key];
        else if (field.key==="bas_version") obj.bas_version={primary: bas?.software||"None", secondary: bas?.version||"", ...bas?.archived===true && {tertiary: BAS_ARCHIVED_LABEL}} as OptionType;
        else if (field.key==="operation_times") obj.operation_times=OPERATION_TIMES.filter((i:any) => ((i.key in values) && values[i.key]===true)).map((i:any) => i.label);
    });

    return obj;
};

/**
 * Base
 * @param {BaseProps} props
 * @return {React.ReactElement}
 */
function Base(props:BaseProps):React.ReactElement|null {
    let form:UseFormReturn=useForm({mode: "onSubmit"});
    if (props.form) form=props.form;
    const {current: fields}=useRef(props.fields);
    // set field options
    [
        {values: props.bases?.data.results.map((i:any) => ({primary: i.software, secondary: i.version, ...i.archived===true && {tertiary: BAS_ARCHIVED_LABEL}} as OptionType)), key: "bas_version"},
        {values: OPERATION_TIMES, key: "operation_times"},
    ].forEach((item:any) => populateValues(item.key==="bas_version"?item.values:item.values.map((i:any) => i.label), props.fields, item.key));

    useEffect(() => {
        if (props.values?.data) form.reset(resolveValues(props.values.data, fields, props.bases));
    }, [props.values?.data, form, props.bases, fields]);

    return (
        <Grid container spacing={4} direction="row" justifyContent="flex-start" alignItems="flex-start">
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}><ControlledTextField field={props.fields[0]} control={form.control} errors={form.formState.errors} /></Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}><ControlledSwitch field={props.fields[1]} control={form.control} errors={form.formState.errors} /></Grid>
            {/* 2-column layout */}
            <Grid item container spacing={4}>
                <Grid item container xs={12} sm={12} md={12} lg={6} xl={6}>
                    <Grid item xs={12}><ControlledTextField field={props.fields[2]} control={form.control} errors={form.formState.errors} /></Grid>
                    <Grid item xs={12}><ControlledAutocomplete field={props.fields[3]} control={form.control} errors={form.formState.errors} /></Grid>
                    <Grid item xs={12}><ControlledLabelSelector field={props.fields[4]} control={form.control} /></Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <ControlledTextField field={props.fields[props.fields.length-2]} control={form.control} errors={form.formState.errors} />
                </Grid>
            </Grid>
            {/* Points */}
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}><PointsGrid values={props.values} field={props.fields[props.fields.length-1]} form={form} /></Grid>
        </Grid>
    );
}

Base.defaultProps={
    form: undefined,
    values: undefined,
    dialog: undefined,
};

/**
 * arePropsEqual
 * @param {BaseProps} prevProps
 * @param {BaseProps} nextProps
 * @return {boolean}
 */
const arePropsEqual=(prevProps:BaseProps, nextProps:BaseProps):boolean => {
    if (nextProps.form?.formState.isValid===false) return false;
    if ((prevProps.form===undefined && nextProps.form===undefined)) return true;
    if (nextProps.dialog!=="NONE") return true;
    return true;
};

const Memoize=memo((props:BaseProps) => <Base {...props} />, arePropsEqual);

export default Memoize;

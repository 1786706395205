import React from "react";
import {Grid} from "@mui/material";
import * as yup from "yup";
import {useFormContext} from "react-hook-form";
import {populateValues} from "../../../handlers";
import {Field, ControlledTextField, ControlledSwitch, ControlledAutocomplete} from "../../generics/inputs";

interface EnertracDetailsProps{
    fields:Field[]
    familiarity:string[]
}

export const Fields:Field[] = [
    {
        key: "rtmeter_access",
        label: "EnerTrac Access",
        yup: yup.mixed().nullable(),
        type: "switch",
        switchOptions: {
            disabled: true,
        },
        exportOptions: {
            label: "EnerTrac_EnerTrac Access",
            order: 8,
        },
    },
    {
        key: "familiarity",
        label: "Level of Familiarity",
        yup: yup.mixed().nullable(),
        type: "autocomplete",
        autocompleteOptions: {
            options: [],
            readOnly: true,
        },
        exportOptions: {
            label: "EnerTrac_Familiarity",
            order: 9,
        },
    },
    {
        key: "notes",
        label: "Notes",
        yup: yup.string(),
        type: "textfield",
        textfieldOptions: {
            readOnly: true,
            maxLength: 400,
            textArea: {
                multiline: true,
                rows: 6,
            },
        },
        exportOptions: {
            label: "EnerTrac_Notes",
            order: 7,
        },
    },
];

/**
 * EnertracDetails
 * @param {EnertracDetailsProps} props
 * @return {React.ReactElement}
 */
function EnertracDetails(props:EnertracDetailsProps):React.ReactElement {
    const methods=useFormContext();
    // set field options
    populateValues(props.familiarity, props.fields, "familiarity");

    return (
        <Grid container spacing={4} direction="row" justifyContent="flex-start" alignItems="flex-start">
            <Grid container item xs={12} sm={12} md={12} lg={6} xl={6} spacing={7}>
                <Grid item xs={12} sm={5} md={5} lg={12} xl={12}><ControlledSwitch field={props.fields[0]} control={methods.control} errors={methods.formState.errors} /></Grid>
                <Grid item xs={12} sm={7} md={7} lg={12} xl={12}><ControlledAutocomplete field={props.fields[1]} control={methods.control} errors={methods.formState.errors} /></Grid>
            </Grid>
            <Grid container item xs={12} sm={12} md={12} lg={6} xl={6} spacing={2}>
                <Grid item xs={12}><ControlledTextField field={props.fields[2]} control={methods.control} errors={methods.formState.errors} /></Grid>
            </Grid>
        </Grid>
    );
}

export default EnertracDetails;
